<!-- *** -->
<div class="section-title-page area-bg area-bg_dark area-bg_op_60">
    <div class="area-bg__inner">
      <div class="container text-center">
        <h1 class="b-title-page">COMPRA TU BOTE</h1>
        <nav aria-label="breadcrumb">
         <!--  <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item"><a href="listing.html">Listing</a></li>
            <li class="breadcrumb-item active" aria-current="page">Post Details</li>
          </ol> -->
        </nav>
        <!-- end .breadcrumb-->
        
      </div>
    </div>
  </div>
<!-- *** -->

<!-- **contenerdor** -->
<div class="l-main-content">
    <div class="ui-decor ui-decor_sm-h ui-decor_mirror bg-primary"></div>
    <div class="container">
      <section class="b-goods-f">
        <div class="row" *ngFor="let con2 of convertido2">

          <div class="col-lg-12"> <br> <br> <br></div>

          <div class="col-lg-7" *ngFor="let c2 of con2">

            <div class="b-goods-f__slider">
              <div class="ui-slider-main js-slider-for">
                 <img class="img-scale" src="{{c2.imagen}}" alt="photo"/>
                  <img class="img-scale" src="{{c2.slider2}}" alt="photo"/>
                  <img class="img-scale" src="{{c2.slider3}}" alt="photo"/>
                  <img class="img-scale" src="{{c2.slider4}}" alt="photo"/>
                </div>
              <div class="ui-slider-nav js-slider-nav">
                  <img class="img-scale" src="{{c2.imagen}}" alt="photo"/>
                  <img class="img-scale" src="{{c2.slider2}}" alt="photo"/>
                  <img class="img-scale" src="{{c2.slider3}}" alt="photo"/>
                  <img class="img-scale" src="{{c2.slider4}}" alt="photo"/>
                </div>
            </div>
            
        
              
                 <h2 class="b-goods-f__title">Especificaciones del barco</h2>
            <div class="row">
              
                
                <div class="col-md-4">
                  
                  <dl class="data-list-descriptions">
                                    <!-- Make -->
                <div class="dd-item">
                    <dt>Modelo:</dt>
                    <dd>{{c2.model}}</dd>
                    </div>
                                                                
                <div class="dd-item">
                    <dt class="left">Precio:</dt>
                    <dd class="right">${{c2.precio}}</dd>
                </div>
                                                                                                
               <!--  <div class="dd-item">
                    <dt class="left">Nombre:</dt>
                    <dd class="right">{{c2.nombre}}</dd>
                </div> -->
                                                                                                
               <!--  <div class="dd-item">
                    <dt class="left">Gross Weight:</dt>
                    <dd class="right">278,000 lb</dd>
                </div> -->
                                                                                                
                
            
                </dl>
              
            </div>
                
                
                <div class="col-md-4">
                  
                  <dl class="data-list-descriptions">
                                    <!-- Make -->
                
                                                                                                
                <div class="dd-item">
                    <dt class="left">Año:</dt>
                    <dd class="right">{{c2.anios}}</dd>
                </div>
                             
                
                <div class="dd-item">
                  <dt class="left">Nombre:</dt>
                  <dd class="right">{{c2.nombre}}</dd>
              </div>
                <!-- <div class="dd-item">
                    <dt class="left">Draft:</dt>
                    <dd class="right">5.92 ft</dd>
                </div> -->
                                                                                                
                <!-- <div class="dd-item">
                    <dt class="left">Fuel Capacity:</dt>
                    <dd class="right">6150 Gallons</dd>
                </div> -->
                                                                                                
                <!-- <div class="dd-item">
                    <dt class="left">Fuel Type:</dt>
                    <dd class="right">Diesel</dd>
                </div> -->
                        </dl>														                    
                
            
            
              
            </div>
                
                
                
                <div class="col-md-4">
                  
                  <dl class="data-list-descriptions">
                                    <!-- Make -->
                <div class="dd-item">
                    <dt class="left">Motores:</dt>
                    <dd class="right">{{c2.motores}}</dd>
                </div>
                      
                      
                    <!--   <div class="dd-item">
                    <dt class="left">Max Speed:</dt>
                    <dd class="right">120</dd>
                </div> -->
                                                                                                
                <!-- <div class="dd-item">
                    <dt class="left">Passengers:</dt>
                    <dd class="right">12</dd>
                </div> -->
                      
                   <!--    <div class="dd-item">
                    <dt class="left">Master Bedroom:</dt>
                    <dd class="right">3</dd>
                </div> -->
                                                                                                
                
                  </dl>
            </div>
                    
                    
                    
                  </div> 
                
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item"><a class="nav-link active" id="overview-tab" data-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">Descripción</a></li>
             <!--  <li class="nav-item"><a class="nav-link" id="features-tab" data-toggle="tab" href="#features" role="tab" aria-controls="features" aria-selected="false">Features & Services</a></li>
              <li class="nav-item"><a class="nav-link" id="location-tab" data-toggle="tab" href="#location" role="tab" aria-controls="location" aria-selected="false">Boats Location</a></li>
              <li class="nav-item"><a class="nav-link" id="reviews-tab" data-toggle="tab" href="#reviews" role="tab" aria-controls="reviews" aria-selected="false">Reviews</a></li> -->
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                <!-- <p>Edipisicing eiusmod tempor incididunt labore sed dolore magna aliqa enim ipsum ad minim veniams quis nostrud citation ullam laboris nisi ut aliquip laboris nisi ut aliquip ex ea commodo. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                   <h3 class="b-goods-f__title-inner">Highlights of the new services </h3> -->
                <p class="justificado">{{c2.descipcion}}</p>
              
              </div>
              <div class="tab-pane fade" id="features" role="tabpanel" aria-labelledby="features-tab">
            
            
                 
                <ul class="list list-mark-2 list-ervices">
                  <li>Enim ipsum ad minim veniams quis</li>
                  <li>Consectetur adipisicing elit</li>
                  <li>Ut enim ad minim veniam quis</li>
                  <li>Laboris nisi ut aliquip ex e comodo </li>
                  <li>Reprehenderit in voluptate velit esse </li>
                     <li>Enim ipsum ad minim veniams quis</li>
                  <li>Consectetur adipisicing elit</li>
                  <li>Ut enim ad minim veniam quis</li>
                  <li>Laboris nisi ut aliquip ex e comodo </li>
                  <li>Reprehenderit in voluptate velit esse </li>
                     <li>Enim ipsum ad minim veniams quis</li>
                  <li>Consectetur adipisicing elit</li>
                  <li>Ut enim ad minim veniam quis</li>
                  <li>Laboris nisi ut aliquip ex e comodo </li>

                </ul>
            
                  
                  
              </div>
              <div class="tab-pane fade" id="location" role="tabpanel" aria-labelledby="location-tab">
                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                  
                      <div class="map" id="map"></div>
                  
                  
              </div>
              <div class="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                
                  
                  
                  
                  <ul class="comments-list list-unstyled">
                    <li>
                      <article class="comment b-goods-reviews__comment clearfix">
                        <header class="comment-header clearfix">
                          <div class="pull-left">
                            <cite class="comment-author">Johannes Kepler</cite>
                            <time datetime="20219-10-27" class="comment-datetime">24 Sep 2019</time>
                          </div>
                          <ul class="rating-list list-inline pull-right">
                             <li class="fa fa-star color-star"></li>
                            <li class="fa fa-star color-star"></li>
                            <li class="fa fa-star color-star"></li>
                            <li class="fa fa-star color-star"></li>
                            <li class="fa fa-star "></li>
                          </ul>
                        </header>
                        <div class="comment-body">
                          <p>Duis ante magna, aliquet sit amet sagittis vitae, tristique at lacus. Ut et accumsan turpis. Phasellus ornare, tortor ut congue imperdiet, mauris magna condimentum nulla, non malesuada mauris massa eu augue.</p>
                        </div>
                      </article>
                    </li>
                    <li>
                      <article class="comment b-goods-reviews__comment clearfix">
                        <header class="comment-header clearfix">
                          <div class="pull-left">
                            <cite class="comment-author">Ruben Chapman</cite>
                            <time datetime="2019-10-27" class="comment-datetime">19 Dec 2019</time>
                          </div>
                          <ul class="rating-list list-inline pull-right">
                            <li class="fa fa-star color-star"></li>
                            <li class="fa fa-star color-star"></li>
                            <li class="fa fa-star color-star"></li>
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                          </ul>
                        </header>
                        <div class="comment-body">
                          <p>Proin non ultricies mauris, non varius massa. Maecenas tempus risus ut erat blandit fermentum. Praesent commodo quam non lacus interdum semper et ut enim. Donec vel suscipit nulla. Nullam imperdiet nisl in lectus porta sodales. Curabitur consequat dui nec eleifend tempor. Pellentesque elementum blandit interdum.</p>
                        </div>
                      </article>
                    </li>
                  </ul>
                  
                  
                   
              </div>
            </div>
       
              
              
              
          </div>
          <div class="col-lg-5">
            <aside class="l-sidebar">
           
              <!-- <div class="b-goods__wrap-bnrs">
                  
                  <img class="b-goods__bnr" src="assets/img/payment-icon_02.png" alt="photo"/>
                  
                   <div class="b-goods-f-price__note">Included Taxes & Checkup*</div>
                
                </div> -->
                
            
                
       
              <!-- end .b-seller-->
              
              <div class="widget-2 section-sidebar bg-gray">



                <h3 class="widget-title-2"><span class="widget-title__inner">Mensaje al Vendedor</span></h3>
                <div class="widget-content">
                  <div class="widget-inner">
                    <div class="sobremarca"></div>
                    
    <iframe
    id="JotFormIFrame-221096131051038"
    title="Formulario"
    onload="window.parent.scrollTo(0,0)"
    allowtransparency="true"
    allowfullscreen="true"
    allow="geolocation; microphone; camera"
    src="https://form.jotform.com/221096131051038"
    frameborder="0"
    style="
    min-width: 100%;
    height:900px;
    border:none;"
    scrolling="no"
  >
  </iframe>
  <script type="text/javascript">
    var ifr = document.getElementById("JotFormIFrame-221096131051038");
    if (ifr) {
      var src = ifr.src;
      var iframeParams = [];
      if (window.location.href && window.location.href.indexOf("?") > -1) {
        iframeParams = iframeParams.concat(window.location.href.substr(window.location.href.indexOf("?") + 1).split('&'));
      }
      if (src && src.indexOf("?") > -1) {
        iframeParams = iframeParams.concat(src.substr(src.indexOf("?") + 1).split("&"));
        src = src.substr(0, src.indexOf("?"))
      }
      iframeParams.push("isIframeEmbed=1");
      ifr.src = src + "?" + iframeParams.join('&');
    }
    window.handleIFrameMessage = function(e) {
      if (typeof e.data === 'object') { return; }
      var args = e.data.split(":");
      if (args.length > 2) { iframe = document.getElementById("JotFormIFrame-" + args[(args.length - 1)]); } else { iframe = document.getElementById("JotFormIFrame"); }
      if (!iframe) { return; }
      switch (args[0]) {
        case "scrollIntoView":
          iframe.scrollIntoView();
          break;
        case "setHeight":
          iframe.style.height = args[1] + "px";
          break;
        case "collapseErrorPage":
          if (iframe.clientHeight > window.innerHeight) {
            iframe.style.height = window.innerHeight + "px";
          }
          break;
        case "reloadPage":
          window.location.reload();
          break;
        case "loadScript":
          if( !window.isPermitted(e.origin, ['jotform.com', 'jotform.pro']) ) { break; }
          var src = args[1];
          if (args.length > 3) {
              src = args[1] + ':' + args[2];
          }
          var script = document.createElement('script');
          script.src = src;
          script.type = 'text/javascript';
          document.body.appendChild(script);
          break;
        case "exitFullscreen":
          if      (window.document.exitFullscreen)        window.document.exitFullscreen();
          else if (window.document.mozCancelFullScreen)   window.document.mozCancelFullScreen();
          else if (window.document.mozCancelFullscreen)   window.document.mozCancelFullScreen();
          else if (window.document.webkitExitFullscreen)  window.document.webkitExitFullscreen();
          else if (window.document.msExitFullscreen)      window.document.msExitFullscreen();
          break;
      }
      var isJotForm = (e.origin.indexOf("jotform") > -1) ? true : false;
      if(isJotForm && "contentWindow" in iframe && "postMessage" in iframe.contentWindow) {
        var urls = {"docurl":encodeURIComponent(document.URL),"referrer":encodeURIComponent(document.referrer)};
        iframe.contentWindow.postMessage(JSON.stringify({"type":"urls","value":urls}), "*");
      }
    };
    window.isPermitted = function(originUrl, whitelisted_domains) {
      var url = document.createElement('a');
      url.href = originUrl;
      var hostname = url.hostname;
      var result = false;
      if( typeof hostname !== 'undefined' ) {
        whitelisted_domains.forEach(function(element) {
            if( hostname.slice((-1 * element.length - 1)) === '.'.concat(element) ||  hostname === element ) {
                result = true;
            }
        });
        return result;
      }
    };
    if (window.addEventListener) {
      window.addEventListener("message", handleIFrameMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", handleIFrameMessage);
    }
    </script>
                <!--     <iframe src="https://form.jotform.com/221096131051038" title="description" height="900" width="100%" style="border: none;"></iframe>
                     -->

                <!--     <form>
                      <div class="form-group">
                        <input class="form-control" type="text" placeholder="Nombre"/>
                      </div>
                      <div class="form-group">
                        <textarea class="form-control" placeholder="Mensaje" rows="5"></textarea>
                      </div>
                      <button class="btn btn-secondary w-100">Enviar Mensaje</button>
                    </form> -->
                  </div>
                </div>
              </div>
              
            </aside>
          </div>
        </div>
      </section>
      <!-- end .b-goods-f-->
      
    </div>
  </div>
<!-- **fin contenedor** -->
