
<div class="b-main-slider slider-pro" id="main-slider" data-slider-width="100%" data-slider-height="100px" data-slider-arrows="false" data-slider-buttons="false">


<div class="swiffy-slider slider-item-ratio slider-item-ratio-16x9 slider-nav-animation slider-nav-animation-fadein" id="swiffy-animation">
    <ul class="slider-container" id="container1">
        <li id="slide1" class=""><img src="../../assets/img/nuevascarrucel/newcarrucel/ca/white.jpg" alt="..." loading="lazy" class="filtro">
        <!-- *** -->
        <div class="" align="center">
            <div class="row">
                <div class="col-lg-12 espaciomovil">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                </div>
                <div class="col-lg-12 espacioslider"></div>
                <div class="col-lg-2 col-md-4"></div>
                <div class="col-lg-8 col-md-7" align="center">
         
                            <div class="b-main-slider__slogan">El Barco de tus Sueños</div>
                            <div class="b-main-slider__title">Encuentra a tu nuevo compañero de aventuras</div>
                            <div class="b-main-slider__label text-secondary" (click)="refrescar('grady_white')">Descubre Grady-White</div>
                      
                
                </div>
            </div>
        </div>

        <!-- *** -->
        
        
        
        </li>

        <!-- *** -->
        <li id="slide1" class=""><img src="../../assets/img/nuevascarrucel/newcarrucel/ca/regulator.jpg" alt="..." loading="lazy" class="filtro">
        
            <!-- *** -->
            <div class="">
                <div class="row">
                    <div class="col-lg-12 espaciomovil">
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                    </div>
                    <div class="col-lg-12 espacioslider"></div>
                    <div class="col-lg-2"></div>
                <div class="col-lg-8" align="center">
         
                            <div class="b-main-slider__slogan">El Barco de tus Sueños</div>
                            <div class="b-main-slider__title">Encuentra a tu nuevo compañero de aventuras</div>
                            <div class="b-main-slider__label text-secondary" (click)="refrescar('regulator')">Descubre Regulator</div>
                      
                
                </div>
                </div>
            </div>
    
            <!-- *** -->
            </li>
        <!-- *** -->

               <!-- *** -->
        <li id="slide1" class=""><img src="../../assets/img/nuevascarrucel/newcarrucel/ca/anonimo.jpg" alt="..." loading="lazy" class="filtro">
        
                <!-- *** -->
                <div class="">
                    <div class="row">
                        <div class="col-lg-12 espaciomovil">
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                        </div>
                        <div class="col-lg-12 espacioslider"></div>
                        <div class="col-lg-2"></div>
                <div class="col-lg-8" align="center">
         
                            <div class="b-main-slider__slogan">El Barco de tus Sueños</div>
                            <div class="b-main-slider__title">Encuentra a tu nuevo compañero de aventuras</div>
                            <div class="b-main-slider__label text-secondary" (click)="refrescar('maritimo')">Descubre Maritimo</div>
                      
                
                </div>
                    </div>
                </div>
        
                <!-- *** -->
        </li>
            <!-- *** -->


            
        <!-- *** -->
        <li id="slide1" class=""><img src="../../assets/img/nuevascarrucel/newcarrucel/ca/shaefer.webp" alt="..." loading="lazy" class="filtro">
        
            <!-- *** -->
            <div class="">
                <div class="row">
                    <div class="col-lg-12 espaciomovil">
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                    </div>
                    <div class="col-lg-12 espacioslider"></div>
                    <div class="col-lg-2"></div>
            <div class="col-lg-8" align="center">
     
                        <div class="b-main-slider__slogan">El Barco de tus Sueños</div>
                        <div class="b-main-slider__title">Encuentra a tu nuevo compañero de aventuras</div>
                        <div class="b-main-slider__label text-secondary" (click)="refrescar('shaefer')">Descubre Shaefer</div>
                  
            
            </div>
                </div>
            </div>
    
            <!-- *** -->
        </li>
        <!-- *** -->

        <!-- *** -->
        <li id="slide1" class=""><img src="../../assets/img/nuevascarrucel/newcarrucel/ca/robalo.jpg" alt="..." loading="lazy" class="filtro">
        
            <!-- *** -->
            <div class="">
                <div class="row">
                    <div class="col-lg-12 espaciomovil">
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                    </div>
                    <div class="col-lg-12 espacioslider"></div>
                    <div class="col-lg-2"></div>
            <div class="col-lg-8" align="center">
     
                        <div class="b-main-slider__slogan">El Barco de tus Sueños</div>
                        <div class="b-main-slider__title">Encuentra a tu nuevo compañero de aventuras</div>
                        <div class="b-main-slider__label text-secondary" (click)="refrescar('robalo')">Descubre Robalo</div>
                  
            
            </div>
                </div>
            </div>
    
            <!-- *** -->
        </li>
        <!-- *** -->

        
        <!-- *** -->
        <li id="slide1" class=""><img src="../../assets/img/nuevascarrucel/newcarrucel/ca/chaparral.jpg" alt="..." loading="lazy" class="filtro">
        
            <!-- *** -->
            <div class="">
                <div class="row">
                    <div class="col-lg-12 espaciomovil">
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                    </div>
                    <div class="col-lg-12 espacioslider"></div>
                    <div class="col-lg-2"></div>
            <div class="col-lg-8" align="center">
     
                        <div class="b-main-slider__slogan">El Barco de tus Sueños</div>
                        <div class="b-main-slider__title">Encuentra a tu nuevo compañero de aventuras</div>
                        <div class="b-main-slider__label text-secondary" (click)="refrescar('chaparral')">Descubre Chaparral</div>
                  
            
            </div>
                </div>
            </div>
    
            <!-- *** -->
        </li>
        <!-- *** -->

        
        <!-- *** -->
        <li id="slide1" class=""><img src="../../assets/img/nuevascarrucel/newcarrucel/ca/solace.webp" alt="..." loading="lazy" class="filtro">
        
            <!-- *** -->
            <div class="">
                <div class="row">
                    <div class="col-lg-12 espaciomovil">
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                    </div>
                    <div class="col-lg-12 espacioslider"></div>
                    <div class="col-lg-2"></div>
            <div class="col-lg-8" align="center">
     
                        <div class="b-main-slider__slogan">El Barco de tus Sueños</div>
                        <div class="b-main-slider__title">Encuentra a tu nuevo compañero de aventuras</div>
                        <div class="b-main-slider__label text-secondary" (click)="refrescar('solace')">Descubre Solace</div>
                  
            
            </div>
                </div>
            </div>
    
            <!-- *** -->
        </li>
        <!-- *** -->
    </ul>

    <button type="button" class="slider-nav" aria-label="Go to previous"></button>
    <button type="button" class="slider-nav slider-nav-next" aria-label="Go to next"></button>

    <div class="slider-indicators">
        <button aria-label="Go to slide" class="active"></button>
        <button aria-label="Go to slide" class=""></button>
        <button aria-label="Go to slide"></button>
        <button aria-label="Go to slide"></button>
        <button aria-label="Go to slide"></button>
        <button aria-label="Go to slide"></button>
        <button aria-label="Go to slide"></button>
    </div>
</div>



    <div class="sp-slides">
        <!-- Slide 1-->
<!--         <div class="b-main-slider__slide b-main-slider__slide-1 sp-slide"><img class="sp-image" src="assets/img/grady1.jpg" alt="slider"  style=""/>
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="sp-layer" data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="500" data-show-delay="400" data-hide-delay="400">
                            <div class="b-main-slider__title-wrap">
                                <div class="b-main-slider__slogan">El Barco de tus Sueños</div>
                                <div class="b-main-slider__title">Encuentra a tu nuevo compañero de aventuras</div>
                                <div class="b-main-slider__label text-secondary">Descubre Grady-White</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Slide 2-->
       <!--  <div class="b-main-slider__slide b-main-slider__slide-1 sp-slide"><img class="sp-image" src="assets/img/regulator41-1.jpg" alt="slider" />
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="sp-layer" data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="500" data-show-delay="400" data-hide-delay="400">
                            <div class="b-main-slider__title-wrap">
                                <div class="b-main-slider__slogan">El Barco de tus Sueños</div>
                                <div class="b-main-slider__title">Encuentra a tu nuevo compañero de aventuras</div>
                                <div class="b-main-slider__label text-secondary">Descubre Regulator</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Slide 2-->
        <!-- <div class="b-main-slider__slide b-main-slider__slide-1 sp-slide"><img class="sp-image" src="assets/img/bertram51-1.jpg" alt="slider" />
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="sp-layer" data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="500" data-show-delay="400" data-hide-delay="400">
                            <div class="b-main-slider__title-wrap">
                                <div class="b-main-slider__slogan">El Barco de tus Sueños</div>
                                <div class="b-main-slider__title">Encuentra a tu nuevo compañero de aventuras</div>
                                <div class="b-main-slider__label text-secondary">Descubre Bertram</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>
        
        
        
        <div class="b-about" id="about">
			<div class="ui-decor ui-decor_down"></div>
			<div class="container">
				<div class="row" style="padding-top: 50px;">
					<div class="col-lg-5">
						<div class="text-left">
							<h2 class="ui-title justificado">Proporcionando una gran flota de barcos para una experiencia perfecta.</h2>
							<div class="ui-content justificado">
								<p>Broker de Servicios náuticos, cuenta con un buen número de embarcaciones, además de una tienda de accesorios y repuestos náuticos, así como taller para la reparación y mantenimiento. La empresa cuenta en la actualidad con una experimentada plantilla de profesionales que trabajan por y para la industria náutica...
									</p>
								<ul class="arrow-list">
									<li><i class="fas fa-long-arrow-alt-right"></i>Barcos y Yates Premium</li>
									<li><i class="fas fa-long-arrow-alt-right"></i>Nuestro enfoque profesional</li>
									<li><i class="fas fa-long-arrow-alt-right"></i>Servicio de Calidad Garantizado</li>
								</ul>
								<div class="gap25"></div> </div>
						</div>
					</div>
					
					<div class="col-lg-7"> 
                        
                                            
                    <!-- partial:index.partial.html -->
                    <div class="row">
                        <div class="col-6 col-md-6 imgtotal">
                            <div class="row">
                            <div class="col-md-12 img1">
                                <img src="../assets/newimg/bertram39cc.jpg" alt="" class="img-fluid">
                            </div>
                            <div class="col-md-12"><br></div>
                            <div class="col-md-12 img2">
                                <img src="../assets/newimg/regulator2.jpg" alt="" class="img-fluid">
                            </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-6">
                            <div class="col-md-12">
                            <br>
                            <br>
                            <br>
                            <img src="../assets/newimg/maritimo-60.png" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>
                    <!-- partial -->  
                        <!-- 
                        <img src="assets/img/maritimo-60.png" alt="photo" class="about-image" style="border-radius: 15%;"> -->
                     </div>
				</div>
			</div>
		</div>
		<!-- end .b-services-->
        <div class="section-advantages">
			<div class="container">
				<div class="row">
					<div class="col-lg-4">
						<div class="b-advantages">
							<div class="ic flaticon-rudder-1 text-secondary"></div>
							<div class="b-advantages__main">
								<div class="b-advantages__title">Venta de botes y yates</div>
								<div class="decore01"></div>
								<div class="b-advantages__info">Ahorre tiempo, en Global Marine encontrará la embarcación a su gusto, nueva o usada</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="b-advantages">
							<div class="ic flaticon-snorkel text-secondary"></div>
							<div class="b-advantages__main">
								<div class="b-advantages__title">Instalación y Mantenimiento</div>
								<div class="decore01"></div>
								<div class="b-advantages__info">Instalación de diferentes tipos de sistemas eléctricos, A/C, Bombas y demás accesorios que necesite</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="b-advantages">
							<div class="ic flaticon-sailor text-secondary"></div>
							<div class="b-advantages__main">
								<div class="b-advantages__title">Servicios Administrativos </div>
								<div class="decore01"></div>
								<div class="b-advantages__info">Contamos con expertos para realizar todos sus trámites administrativos,  trámites en la AMP o avalúos </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
<!-- ***fin**** -->
<!-- Start Logo Carousel -->


<!-- ***aqui iva el carrucel -->


<!-- <div id="logo-carousel">
    <div class="container pt-3 text-center">
        <div class="swiffy-slider slider-item-show4   slider-nav-sm slider-nav-visible   slider-nav-autoplay slider-nav-autopause slider-item-ratio slider-item-ratio-contain slider-item-ratio-32x9  shadow-lg py-3 py-lg-4" data-slider-nav-autoplay-interval="2000" style="background: #ededed;">
            <div class="slider-container">
                <div class="item py-3">
                    <img class="" src="assets/img/logos/Grady - White (2).png" alt="" (click)="refrescar('grady_white')">
                </div>

                <div class="item py-3">
                    <img class="" src="assets/img/logos/Regulator (2).png" alt="" (click)="refrescar('regulator')">
                </div>

                <div class="item py-3">
                    <img class="" src="assets/img/logos/Maritimo.png" alt="" (click)="refrescar('maritimo')"> 
                </div>

                <div class="item py-3">
                    <img class="" src="assets/img/logos/bertram.png" alt="">
                </div>

                <div class="item py-3">
                    <img class="" src="assets/img/logos/schaefer-logo.png" alt="" (click)="refrescar('shaefer')">
                </div>
 
            </div>
        
            <button type="button" class="slider-nav" aria-label="Go left"></button>
            <button type="button" class="slider-nav slider-nav-next" aria-label="Go left"></button>
        
        </div>
    </div>
</div>  -->


<!-- End Logo Carousel -->

<!-- ***fin carrusel*** -->

<section class="section-goods">
    <div class="section-default section-goods__inner bg-dark">
        <div class="ui-decor ui-decor_mirror ui-decor_center"></div>
        <div class="container">
            <div class="text-center">
                <h2 class="ui-title ui-title_light">Hay más de una forma de atrapar un pez.</h2>
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <p>Deje atrás la costa y diríjase a alta mar y demuestre la destreza y las capacidades únicas de su bote.</p> <img src="assets/img/decore03.png" alt="photo"> </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="section-goods__list">
            <div class="row" *ngFor="let con2 of constructor2">
                <div class="col-xl-3 col-md-6" *ngFor="let c2 of con2">
                    <div class="b-goods">
                        <a class="b-goods__img" href="{{c2.url}}" target="_blank"><img class="img-scale" src="{{c2.imagen}}" alt="photo" /></a>
                        <div class="b-goods__main">
                            <div class="row no-gutters">
                                <div class="col"><a class="b-goods__title" href="{{c2.url}}" target="_blank">{{c2.nombre}}</a>
                                    <div class="b-goods__info">Stock#: 45098ES - 4 door, White, 2.5L, FWD, Automatic 6-Speed, 2.5L 5 cyls, Florida CA</div>
                                </div>
                                <div class="col-auto">
                                    <div class="b-goods__price text-primary"><span class="b-goods__price-title">DEALER<br>PRICE</span><span class="b-goods__price-number">
                                            <span class="b-goods__price-after-price"></span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="b-goods-descrip_nev_wrap">
                                <div class="b-goods-descrip_nev">
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-gas-pump"></i> <span class="b-goods-descrip__info">{{c2.invitados}} Invitados</span> </div>
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-bed"></i> <span class="b-goods-descrip__info">{{c2.dormitorios}} Dormitorios</span> </div>
                                </div>
                                <div class="b-goods-descrip_nev">
                                   <!--  <span>{{c2.minidescripcion}}</span> -->
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-arrows-alt-h"></i> <span class="b-goods-descrip__info"> {{c2.pies}} Pies</span> </div>
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-columns"></i> <span class="b-goods-descrip__info"> {{c2.cocina}} Cocina</span> </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3 col-md-6">
                    <div class="b-goods">
                        <a class="b-goods__img" href="#"><img class="img-scale" src="assets/img/home/schaeferv33.webp" alt="photo" /></a>
                        <div class="b-goods__main">
                            <div class="row no-gutters">
                                <div class="col"><a class="b-goods__title" href="#">Schaefer</a>
                                    <div class="b-goods__info">Stock#: 45098ES - 4 door, White, 2.5L, FWD, Automatic 6-Speed, 2.5L 5 cyls, Florida CA</div>
                                </div>
                                <div class="col-auto">
                                    <div class="b-goods__price text-primary"><span class="b-goods__price-title">DEALER<br>PRICE</span><span class="b-goods__price-number">V33
                                            <span class="b-goods__price-after-price">CC</span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="b-goods-descrip_nev_wrap">
                                <div class="b-goods-descrip_nev">
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-user"></i> <span class="b-goods-descrip__info">12 Guests</span> </div>
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-bed"></i> <span class="b-goods-descrip__info">2 Master Bedroom</span> </div>
                                </div>
                                <div class="b-goods-descrip_nev">
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-arrows-alt-h"></i> <span class="b-goods-descrip__info"> 44 Feet</span> </div>
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-columns"></i> <span class="b-goods-descrip__info"> Sun Deck, Kitchen ...</span> </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="b-goods">
                            <a class="b-goods__img" href="#"><img class="img-scale" src="assets/img/home/regulator24xo.jpg" alt="photo" /></a>
                        <div class="b-goods__main">
                            <div class="row no-gutters">
                                <div class="col"><a class="b-goods__title" href="#">Regulator</a>
                                    <div class="b-goods__info">Stock#: 45098ES - 4 door, White, 2.5L, FWD, Automatic 6-Speed, 2.5L 5 cyls, Florida CA</div>
                                </div>
                                <div class="col-auto">
                                    <div class="b-goods__price text-primary"><span class="b-goods__price-title">DEALER<br>PRICE</span><span class="b-goods__price-number">24XO
                                            <span class="b-goods__price-after-price">CC</span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="b-goods-descrip_nev_wrap">
                                <div class="b-goods-descrip_nev">
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-user"></i> <span class="b-goods-descrip__info">12 Guests</span> </div>
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-bed"></i> <span class="b-goods-descrip__info">2 Master Bedroom</span> </div>
                                </div>
                                <div class="b-goods-descrip_nev">
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-arrows-alt-h"></i> <span class="b-goods-descrip__info"> 44 Feet</span> </div>
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-columns"></i> <span class="b-goods-descrip__info"> Sun Deck, Kitchen ...</span> </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="b-goods">
                        <a class="b-goods__img" href="#"><img class="img-scale" src="assets/img/home/bertram28cc.jpg" alt="photo" /></a>
                        <div class="b-goods__main">
                            <div class="row no-gutters">
                                <div class="col"><a class="b-goods__title" href="#">Bertram</a>
                                    <div class="b-goods__info">Stock#: 45098ES - 4 door, White, 2.5L, FWD, Automatic 6-Speed, 2.5L 5 cyls, Florida CA</div>
                                </div>
                                <div class="col-auto">
                                    <div class="b-goods__price text-primary"><span class="b-goods__price-title">DEALER<br>PRICE</span><span class="b-goods__price-number">28CC
                                            <span class="b-goods__price-after-price">CC</span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="b-goods-descrip_nev_wrap">
                                <div class="b-goods-descrip_nev">
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-user"></i> <span class="b-goods-descrip__info">12 Guests</span> </div>
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-bed"></i> <span class="b-goods-descrip__info">2 Master Bedroom</span> </div>
                                </div>
                                <div class="b-goods-descrip_nev">
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-arrows-alt-h"></i> <span class="b-goods-descrip__info"> 44 Feet</span> </div>
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-columns"></i> <span class="b-goods-descrip__info"> Sun Deck, Kitchen ...</span> </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="b-goods">
                        <a class="b-goods__img" href="#"><img class="img-scale" src="assets/img/home/regulator37.jpg" alt="photo" /></a>
                        <div class="b-goods__main">
                            <div class="row no-gutters">
                                <div class="col"><a class="b-goods__title" href="#">Regulator</a>
                                    <div class="b-goods__info">Stock#: 45098ES - 4 door, White, 2.5L, FWD, Automatic 6-Speed, 2.5L 5 cyls, Florida CA</div>
                                </div>
                                <div class="col-auto">
                                    <div class="b-goods__price text-primary"><span class="b-goods__price-title">DEALER<br>PRICE</span><span class="b-goods__price-number">37
                                            <span class="b-goods__price-after-price">CC</span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="b-goods-descrip_nev_wrap">
                                <div class="b-goods-descrip_nev">
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-user"></i> <span class="b-goods-descrip__info">12 Guests</span> </div>
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-bed"></i> <span class="b-goods-descrip__info">2 Master Bedroom</span> </div>
                                </div>
                                <div class="b-goods-descrip_nev">
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-arrows-alt-h"></i> <span class="b-goods-descrip__info"> 44 Feet</span> </div>
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-columns"></i> <span class="b-goods-descrip__info"> Sun Deck, Kitchen ...</span> </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="b-goods">
                        <a class="b-goods__img" href="#"><img class="img-scale" src="assets/img/home/grady-express-370.jpg" alt="photo" /></a>
                        <div class="b-goods__main">
                            <div class="row no-gutters">
                                <div class="col"><a class="b-goods__title" href="#">Grady-White</a>
                                    <div class="b-goods__info">Stock#: 45098ES - 4 door, White, 2.5L, FWD, Automatic 6-Speed, 2.5L 5 cyls, Florida CA</div>
                                </div>
                                <div class="col-auto">
                                    <div class="b-goods__price text-primary"><span class="b-goods__price-title">DEALER<br>PRICE</span><span class="b-goods__price-number">370
                                            <span class="b-goods__price-after-price">Express</span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="b-goods-descrip_nev_wrap">
                                <div class="b-goods-descrip_nev">
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-user"></i> <span class="b-goods-descrip__info">12 Guests</span> </div>
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-bed"></i> <span class="b-goods-descrip__info">2 Master Bedroom</span> </div>
                                </div>
                                <div class="b-goods-descrip_nev">
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-arrows-alt-h"></i> <span class="b-goods-descrip__info"> 44 Feet</span> </div>
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-columns"></i> <span class="b-goods-descrip__info"> Sun Deck, Kitchen ...</span> </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="b-goods">
                        <a class="b-goods__img" href="#"><img class="img-scale" src="assets/img/home/bertram-35fb.jpg" alt="photo" /></a>
                        <div class="b-goods__main">
                            <div class="row no-gutters">
                                <div class="col"><a class="b-goods__title" href="#">Bertram</a>
                                    <div class="b-goods__info">Stock#: 45098ES - 4 door, White, 2.5L, FWD, Automatic 6-Speed, 2.5L 5 cyls, Florida CA</div>
                                </div>
                                <div class="col-auto">
                                    <div class="b-goods__price text-primary"><span class="b-goods__price-title">DEALER<br>PRICE</span><span class="b-goods__price-number">35FB
                                            <span class="b-goods__price-after-price">Flybridge</span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="b-goods-descrip_nev_wrap">
                                <div class="b-goods-descrip_nev">
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-user"></i> <span class="b-goods-descrip__info">12 Guests</span> </div>
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-bed"></i> <span class="b-goods-descrip__info">2 Master Bedroom</span> </div>
                                </div>
                                <div class="b-goods-descrip_nev">
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-arrows-alt-h"></i> <span class="b-goods-descrip__info"> 44 Feet</span> </div>
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-columns"></i> <span class="b-goods-descrip__info"> Sun Deck, Kitchen ...</span> </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="b-goods">
                        <a class="b-goods__img" href="#"><img class="img-scale" src="assets/img/home/grady-freedom-306.jpg" alt="photo" /></a>
                        <div class="b-goods__main">
                            <div class="row no-gutters">
                                <div class="col"><a class="b-goods__title" href="#">Grady-White</a>
                                    <div class="b-goods__info">Stock#: 45098ES - 4 door, White, 2.5L, FWD, Automatic 6-Speed, 2.5L 5 cyls, Florida CA</div>
                                </div>
                                <div class="col-auto">
                                    <div class="b-goods__price text-primary"><span class="b-goods__price-title">DEALER<br>PRICE</span><span class="b-goods__price-number">Freedom306
                                            <span class="b-goods__price-after-price">Dual Console</span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="b-goods-descrip_nev_wrap">
                                <div class="b-goods-descrip_nev">
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-user"></i> <span class="b-goods-descrip__info">12 Guests</span> </div>
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-bed"></i> <span class="b-goods-descrip__info">2 Master Bedroom</span> </div>
                                </div>
                                <div class="b-goods-descrip_nev">
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-arrows-alt-h"></i> <span class="b-goods-descrip__info"> 44 Feet</span> </div>
                                    <div class="b-goods-descrip__nev"> <i class="fas fa-columns"></i> <span class="b-goods-descrip__info"> Sun Deck, Kitchen ...</span> </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="text-center mt-3"><a class="btn btn-border view-all-boats"  (click)="refrescar('grady_white')">Ver Mas</a></div>
        </div>
    </div>
</section>

<!-- ***fin*** -->

<section class="section-video section-default section-goods__inner bg-dark ">
            
    <div class="ui-decor ui-decor_mirror ui-decor_center"></div>
    
    
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-10 col-lg-10">
                <div class="video-info">
                    <p><img src="assets/img/decore02.png" alt="decore">Calidad inigualable de Regulator Marine. Un bote indestructible!</p>
                    <h5>Regulator "Queen Bee" sobrevive un viaje transatlántico de 3,500 millas</h5>
                </div>
            </div>
            <div class="col-12 col-md-2 col-lg-2"> 
                <a class="video-btn venobox ternary-video-btn-style vbox-item" href="https://www.youtube.com/channel/UCSGWK1yeejbEHWz5rNAkp7w" target="_blank"><i class="fa fa-play"></i>
                    <div class="pulsing-bg"></div>
                       <span>Descubre Más </span> 
                </a> 
        
            
            </div>
        </div>
    </div>
</section>

<!-- **fin** -->

<section class="section-gallery">
    <div class="container">
        <div class="text-center">
            <h2 class="ui-title">Galería de Imágenes</h2>
            <div class="row">
                <div class="col-md-8 offset-md-2">
                   <!--  <p>Dolore magna aliqua enim ad minim veniam, quis nostrud exercitation aliquip duis aute irure dolorin <br> reprehenderits vol dolore fugiat nulla pariatur excepteur sint occaecat cupidatat.</p>  --><img src="assets/img/decore03.png" alt="photo"> </div>
            </div>
        </div>
    </div>
    <div class="ui-gallery js-zoom-gallery">
        <div class="row no-gutters">
            <div class="col-lg-3 col-sm-6">
                <a class="ui-gallery__img js-zoom-gallery__item" href="../../assets/newbote/b1.webp"><img class="img-scale" src="../../assets/newbote/b1.webp" alt="photo"></a>
            </div>
            <div class="col-lg-3 col-sm-6">
                <a class="ui-gallery__img js-zoom-gallery__item" href="../../assets/newbote/Adventure208.webp"><img class="img-scale" src="../../assets/newbote/Adventure208.webp" alt="photo"></a>
            </div>
            <div class="col-lg-2 col-sm-6">
                <a class="ui-gallery__img js-zoom-gallery__item" href="../../assets/newbote/canyon376.webp"><img class="img-scale" src="../../assets/newbote/canyon376.webp" alt="photo"></a>
            </div>
            <div class="col-lg-4 col-sm-6">
                <a class="ui-gallery__img js-zoom-gallery__item" href="../../assets/newbote/V600.webp"><img class="img-scale" src="../../assets/newbote/V600.webp" alt="photo"></a>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-lg-5 col-sm-6">
                <a class="ui-gallery__img js-zoom-gallery__item" href="../../assets/newbote/v511.webp"><img class="img-scale" src="../../assets/newbote/v511.webp" alt="photo"></a>
            </div>
            <div class="col-lg-2 col-sm-6">
                <a class="ui-gallery__img js-zoom-gallery__item" href="../../assets/newbote/v33.webp"><img class="img-scale" src="../../assets/newbote/v33.webp" alt="photo"></a>
            </div>
            <div class="col-lg-3 col-sm-6">
                <a class="ui-gallery__img js-zoom-gallery__item" href="../../assets/newbote/b3_1.webp"><img class="img-scale" src="../../assets/newbote/b3_1.webp" alt="photo"></a>
            </div>
            <div class="col-lg-2 col-sm-6">
                <a class="ui-gallery__img js-zoom-gallery__item" href="../../assets/newbote/regulator23.webp"><img class="img-scale" src="../../assets/newbote/regulator23.webp" alt="photo"></a>
            </div>
        </div>
    </div>
</section>

<!-- **fin** -->

