<!-- *** -->

<div class="b-main-slider slider-pro" id="main-slider" data-slider-width="100%" data-slider-height="100px" data-slider-arrows="false" data-slider-buttons="false">
  <div class="swiffy-slider slider-item-ratio slider-item-ratio-16x9 slider-nav-animation slider-nav-animation-fadein" id="swiffy-animation" *ngFor="let con2 of convertido3">
      <ul class="slider-container" id="container1" *ngFor="let c2 of con2">
          <li id="slide1" class="" align="center">
            <img src="{{c2.imagen}}" alt="..." loading="lazy">
          <!-- *** -->
   <!--        <div class="col-lg-12 espacioslider"></div>
              
          <h1 class="b-main-slider__title" style="color: #fff; ">{{c2.marca}}</h1> -->
          <div class="" align="center">
              <div class="">
                  <div class="col-lg-12 espaciomovil">
                      
                  </div>
                  <div class="col-lg-12 espacioslider"></div>
              
                  <div class="" align="center">
           
                    <div class="b-main-slider__title">
                      <!-- <img src="{{img}}" alt="" class="img-fluid"> -->
                    </div>
                    <p></p>
                        
                  
                  </div>
              </div>
          </div>
          <!-- *** -->
          </li>
      </ul>
  </div>
  </div>
<!-- *** -->
<!-- *** -->
<!-- <div class="section-title-page area-bg area-bg_dark area-bg_op_60">
    <div class="area-bg__inner" *ngFor="let con2 of convertido3">
      <div class="container text-center" *ngFor="let c2 of con2">
        <h1 class="b-title-page" >{{c2.marca}}</h1>
        <nav aria-label="breadcrumb">
          <p style="color: #fff;">
          {{c2.descrip}}
          </p>
        </nav>
      </div>
    </div>
  </div> -->
<!-- *** -->

<!-- **contenedor** -->

<div class="b-about" id="about">
  <div class="ui-decor ui-decor_down"></div>
  <div class="container">
    <div class="row" style="padding-top: 50px;">
      <div class="col-lg-6">
        <div class="text-left">
          <h2 class="ui-title">Proporcionando una gran flota de barcos para una experiencia perfecta.</h2>
          <div class="ui-content">
            <p>Broker de Servicios náuticos, cuenta con un buen número de embarcaciones, además de una tienda de accesorios y repuestos náuticos, así como taller para la reparación y mantenimiento. La empresa cuenta en la actualidad con una experimentada plantilla de profesionales que trabajan por y para la industria náutica...
              </p>
            <ul class="arrow-list">
              <li><i class="fas fa-long-arrow-alt-right"></i>Barcos y Yates Premium</li>
              <li><i class="fas fa-long-arrow-alt-right"></i>Nuestro enfoque profesional</li>
              <li><i class="fas fa-long-arrow-alt-right"></i>Servicio de Calidad Garantizado</li>
            </ul>
            <div class="gap25"></div> </div>
        </div>
      </div>
      
      <div class="col-lg-6"> 
                    
                    
<!-- partial:index.partial.html -->
<div class="row">
<div class="col-6 col-md-6 imgtotal">
    <div class="row">
      <div class="col-md-12 img1">
        <img src="../assets/newimg/bertram39cc.jpg" alt="" class="img-fluid">
      </div>
      <div class="col-md-12"><br></div>
      <div class="col-md-12 img2">
        <img src="../assets/newimg/regulator2.jpg" alt="" class="img-fluid">
      </div>
    </div>
  </div>
  <div class="col-6 col-md-6">
    <div class="col-md-12">
      <br>
      <br>
      <br>
      <img src="../assets/newimg/maritimo-60.png" alt="" class="img-fluid">
    </div>
  </div>
</div>
<!-- partial -->
                    <!-- 
                    <img src="assets/img/maritimo-60.png" alt="photo" class="about-image" style="border-radius: 15%;"> -->
                 </div>
    </div>
  </div>
</div>

<!-- *** -->
<section class="section-goods" style="padding-bottom: 0px;">
  <div class="section-default section-goods__inner bg-dark">
    <div class="ui-decor ui-decor_mirror ui-decor_center"></div>
    <div class="container">
      <div class="text-center">
        <h2 class="ui-title ui-title_light">Sit back. Relax. Nosotros nos encargamos del resto...</h2>
        <div class="row">
          <div class="col-md-8 offset-md-2">
             <img src="assets/img/decore03.png" alt="photo"> </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- *** -->
<div class=" l-main-content">

  <br>
  <div class="container">
    <div class="text-center" *ngFor="let con2 of convertido3">
        <h4 class="ui-title" *ngFor="let c2 of con2">{{c2.descrip}}</h4>
        <div class="row">
            <div class="col-md-8 offset-md-2">
               <!--  <p>Dolore magna aliqua enim ad minim veniam, quis nostrud exercitation aliquip duis aute irure dolorin <br> reprehenderits vol dolore fugiat nulla pariatur excepteur sint occaecat cupidatat.</p>  --><img src="assets/img/decore03.png" alt="photo"> </div>
        </div>
    </div>
</div>
<br>
<!--     <div class="ui-decor ui-decor_mirror ui-decor_sm-h bg-primary"></div>
 -->

   <div class="container">
       
       
<div class="row">

  
  
<div class="col-xl-12">

    
<!--     <select  (change)="change($event)">
        <option value="">seleccionar</option>
        <option value="9">9</option>
        <option value="8">8</option>
    </select>

    <select  (change)="change2($event)">
        <option value="">seleccionar</option>
        <option value="disponible">disponible</option>
        <option value="AGOTADO">AGOTADO</option>
    </select> -->

<!-- <div *ngFor="let con of convertido2" >
    <ul *ngFor="let c of con">
        <li>{{c.precio}}</li>
    </ul>
</div> -->
    


<div class="b-goods-group-2 row" *ngFor="let con of convertido2">

<div class="col-xl-4 col-md-6" *ngFor="let c of con">
  <div class="b-goods-flip">
    
    <div class="flip-container">
      <div class="flipper">
        <div class="flip__front">
          <div class="b-goods-flip__img"><img class="img-scale" src="{{c.imagen}}" alt="photo"/></div>
          <div class="b-goods-flip__main">
            <div class="b-goods-flip__header row no-gutters align-items-center">
              <div class="col"><a class="b-goods-flip__title" href="{{c.url}}" target="_blank">{{c.nombre}}</a></div>
              <div class="col-auto">
                <!-- <div class="b-goods-flip__price text-primary">${{c.precio}} <span>/ per day</span></div> -->
              </div>
            </div>
              <div class="b-goods-descrip_nev_wrap">
                    <div class="b-ex-info">{{c.minidescripcion}}</div>
                    <!-- <a class="btn btn-default w-100" (click)="somefunctionName()">VER MAS</a> -->
                    <a class="btn btn-default w-100" href="{{c.url}}" target="_blank">VER MAS</a>
             </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>   <!-- end .b-goods-->
  
</div>


<nav aria-label="Page navigation example">
<!-- <ul class="pagination justify-content-center">
  <li class="page-item"><a class="page-link" href="#">1</a></li>
  <li class="page-item active"><a class="page-link" href="#">2</a></li>
  <li class="page-item"><a class="page-link" href="#">3</a></li>
</ul> -->
</nav>
  


</div>
</div>



</div>




</div>



<!-- **fin contenedor** -->