<!-- *** -->
<div class="section-title-page area-bg area-bg_dark area-bg_op_60">
    <div class="area-bg__inner">
      <div class="container text-center">
        <h1 class="b-title-page">COMPRA TU BOTE</h1>
        <nav aria-label="breadcrumb">
<!--           <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item"><a href="listing.html">Listing</a></li>
            <li class="breadcrumb-item active" aria-current="page">Post Details</li>
          </ol> -->
        </nav>
        <!-- end .breadcrumb-->
        
      </div>
    </div>
  </div>
<!-- *** -->

<!-- **contenedor** -->

<div class="l-main-content">
    <div class="ui-decor ui-decor_mirror ui-decor_sm-h bg-primary"></div>


   <div class="container">
       
       
<div class="row">

  <div class="col-xl-12">
    <br>
    <br>
  </div>

<div class="col-xl-3">
  <aside class="l-sidebar l-sidebar_top_minus">
    <div class="widget section-sidebar bg-gray">
      <h3 class="widget-title row justify-content-between align-items-center no-gutters"><span class="widget-title__inner col">Opciones de Busqueda</span><i class="ic flaticon-car-repair col-auto"></i></h3>
      <div class="widget-content">
        <div class="widget-inner">
          <form class="b-filter">
            <div class="b-filter__main row">
              <div class="b-filter__row col-xl-12 col-md-6">
                <select class="selectpicker" data-width="100%" title=" Tipo de Lancha" multiple="multiple" data-max-options="1" data-style="ui-select" (change)="make($event)" >
                  <option value="">Tipo de Lancha</option>
                  <option>Express</option>
                  <option>Consola Center</option>
                  <option>Dual Center</option>
                </select>
              </div>
              <div class="b-filter__row col-xl-12 col-md-6">
                <select class="selectpicker" data-width="100%" title=" Marca" multiple="multiple" data-max-options="1" data-style="ui-select" (change)="model($event)">
                  <option value="">Marca</option>
                  <option>GRADY WHITE</option>
                  <option>REGULATOR</option>
                  <option>ROBALO</option>
                  <option>MARITIMO</option>
                  <option>SCHAEFER</option>
                </select>
              </div>
              <div class="b-filter__row col-xl-12 col-md-6">
                <select class="selectpicker" data-width="100%" title=" Condicion" multiple="multiple" data-max-options="1" data-style="ui-select" (change)="condicion($event)" [(ngModel)]="selectedCalculation">
                  <option value="">Condicion</option>
                  <option>Usado en buen estado</option>
                  <option>Usado como nuevo</option>
                  <option>Usado </option>
                  <option>Nuevo </option>
                </select>
              </div>
             <!--  <div class="b-filter__row col-xl-12 col-md-6">
                <select class="selectpicker" data-width="100%" title="Condiciones" multiple="multiple" data-max-options="1" data-style="ui-select" (change)="condicion($event)">
                  <option value="">Seleccionar</option>
                  <option>Condicion 1</option>
                  <option>Condicion 2</option>
                </select>
              </div> -->
              <!-- <div class="col-12">
                <div class="b-filter__row row">
                  <div class="b-filter__item col-md-6">
                    <select class="selectpicker" data-width="100%" title="From" multiple="multiple" data-max-options="1" data-style="ui-select">
                      <option>$1000</option>
                      <option>$2000</option>
                    </select>
                  </div>
                  <div class="b-filter__item col-md-6">
                    <select class="selectpicker" data-width="100%" title="To" multiple="multiple" data-max-options="1" data-style="ui-select">
                      <option>$1000</option>
                      <option>$2000</option>
                    </select>
                  </div>
                </div>
              </div> -->
              
              <!-- <div class="b-filter__row col-xl-12 col-md-6">
                <select class="selectpicker" data-width="100%" title="Fuel Type" multiple="multiple" data-max-options="1" data-style="ui-select">
                  <option>Tipo 1</option>
                  <option>Tipo 2</option>

                </select>
              </div> -->
            </div>
            <!-- <div class="b-filter-slider ui-filter-slider">
              <div class="b-filter-slider__title">Precio</div>
              <div class="b-filter-slider__main">
            
                <div class="row">
                  <div class="col-6 col-md-6" style="padding-left: 0px; padding-right: 0px;">
                    <input type="range" min="0" max="5000" class="rangeizquierda" (change)="preciominimo($event)">
                  </div>
                  <div class="col-6 col-md-6" style="padding-left: 0px; padding-right: 0px;">
                    <input type="range" min="0" max="5000" class="rangederecha" (change)="preciomaximos($event)">
                  </div>
                </div>
                <div class="b-filter__row row">
                  <div class="b-filter__item col-md-6">
                    <input class="ui-select" id="input-with-keypress-0"  value="{{totalminimo}}"/>
                  </div>
                  <div class="b-filter__item col-md-6">
                    <input class="ui-select" id="input-with-keypress-1" value="{{totalmaximo}}"/>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="b-filter-slider ui-filter-slider">
              <div class="b-filter-slider__title">Buscar Radio</div>
              <div class="b-filter-slider__main">
             
                <input type="range" min="0" max="500" class="rangederecha" (change)="kilometro($event)">
                <div class="b-filter__row">
                  <input class="ui-select" id="input-range" value="{{totalkilometro}}"/>
                </div>
              </div>
            </div> -->
            <button class="b-filter__reset btn btn-secondary w-100" type="reset" (click)="resetear()">Limpiar Filtros</button>
          </form>
        </div>
      </div>
    </div>
    <!-- end .b-filter-->
    
  </aside>
</div>
  
  
<div class="col-xl-9">

    <h1>{{busquedanula}}</h1>
<!--     <select  (change)="change($event)">
        <option value="">seleccionar</option>
        <option value="9">9</option>
        <option value="8">8</option>
    </select>

    <select  (change)="change2($event)">
        <option value="">seleccionar</option>
        <option value="disponible">disponible</option>
        <option value="AGOTADO">AGOTADO</option>
    </select> -->

<!-- <div *ngFor="let con of convertido2" >
    <ul *ngFor="let c of con">
        <li>{{c.precio}}</li>
    </ul>
</div> -->
    


<div class="b-goods-group-2 row" *ngFor="let con of convertido2">

<div class="col-xl-4 col-md-6" *ngFor="let c of con">
  <div class="b-goods-flip">
    
    <div class="flip-container">
      <div class="flipper">
        <div class="flip__front">
          <div class="b-goods-flip__img"><img class="img-scale" src="{{c.imagen}}" alt="photo"/></div>
          <div class="b-goods-flip__main">
            <div class="b-goods-flip__header row no-gutters align-items-center">
              <div class="col"><a class="b-goods-flip__title" [routerLink]="['/detalle_bote', '.', c.positionId]">{{c.nombre}}</a></div>
              <div class="col-auto">
                <!-- <div class="b-goods-flip__price text-primary">${{c.precio}} <span>/ per day</span></div> -->
              </div>
            </div>
              <div class="b-goods-descrip_nev_wrap">
                    <div class="b-ex-info">{{c.minidescripcion}}</div>
                    <!-- <a class="btn btn-default w-100" (click)="somefunctionName()">VER MAS</a> -->
                    <a class="btn btn-default w-100" [routerLink]="['/detalle_bote', c.positionId]"  (click)="detallebote(c.positionId)">VER MAS</a>
             </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>   <!-- end .b-goods-->
  
</div>


<nav aria-label="Page navigation example">
<ul class="pagination justify-content-center">
 <!--  <li class="page-item"><a class="page-link" href="#">1</a></li>
  <li class="page-item active"><a class="page-link" href="#">2</a></li>
  <li class="page-item"><a class="page-link" href="#">3</a></li> -->
</ul>
</nav>
  


</div>
</div>



</div>




</div>

<!-- **fin contenedor** -->