import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-compra-bote',
  templateUrl: './compra-bote.component.html',
  styleUrls: ['./compra-bote.component.css']
})
export class CompraBoteComponent implements OnInit {

  
  constructor(private rutaActiva: ActivatedRoute, private router: Router) {}
  var;
  id=[];   
  convertido2 = [];
  estados="";
  precios="";
  refs="";
  nombre="";
  selectedCalculation;
  busquedanula;

  somefunctionName() {
    let link = ['/detalle_bote', '.', '1'];
    this.router.navigate(link);
}


arraymantenimiento = [{
  "imagen": "../../assets/newbote/b1.webp",
  "nombre": "Four Winns 34",
  "precio": 1000,
  "make": "Express",
  "model": "GRADY WHITE",
  "condicion": "Usado en buen estado",
  "kilometros": 450,
  "tipo":"Tipo 1",
  "descipcion": "Es una combinación notable de rendimiento de embarcación deportiva y comodidades similares a las de un crucero. Desde exclusivos asientos estilo diván hasta un compartimento completo para la cabeza y una litera, todo en este barco fue cuidadosamente diseñado y elaborado para satisfacer al navegante más exigente.",
  "minidescripcion": "Es una combinación notable de rendimiento de embarcación deportiva",
  "positionId": 1
},
{
  "imagen": "../../assets/newbote/b2_1.webp",
  "nombre": "Intrepid 2010",
  "precio": 2000,
  "make": "Consola Center",
  "model": "REGULATOR",
  "condicion": "Nuevo",
  "kilometros": 300,
  "tipo":"Tipo 2",
  "descipcion": "Excelente ejemplar, equipado con triples motores fuera de borda Suzuki de 300 Hp. El barco perfecto para pescar, ir de playa en playa y navegar.",
  "minidescripcion": "Excelente ejemplar, equipado con triples motores fuera de borda Suzuki ",
  "positionId": 2
},
{
  "imagen": "../../assets/newbote/b3_1.webp",
  "nombre": "FREEDOM 33.5",
  "precio": 3000,
  "make": "Dual Center",
  "model": "ROBALO",
  "condicion": "Usado",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "descipcion": "Esta consola doble de 33 pies es una excelente embarcación de agua salada, perfecta para la pesca en alta mar pero repleta de comodidades, ¡lo que lo convierte en muchos días excepcionales en el agua! Además de dos áreas de consola que incluyen una cabecera de fácil acceso y una litera espaciosa, el  Freedom 335  incluye servicios de entretenimiento superiores completos con barra con fregadero, refrigerador y parrilla. Con asientos ultracómodos y amplios en la proa, un asiento de banco envolvente con mesa y el banco plegable de popa patentado de Grady que se puede cerrar para permitir más espacio en la cabina cuando se pesca, este es el barco perfecto para familias multigeneracionales que desean disfrutar el tiempo juntos. en el agua.",
  "minidescripcion": "Esta consola doble de 33 pies es una excelente embarcación de agua salada",
  "positionId": 3
}
]

newarrary2=[];

preciomini=0;
preciomaximo=5000;

totalminimo=0;
totalmaximo=0;

preciominimo(v){
  this.preciomini=v.target.value;
  this.totalminimo=v.target.value;
  this.cargavariable()
}

preciomaximos(v){
  this.preciomaximo=v.target.value;
  this.totalmaximo=v.target.value;
  this.cargavariable()
}

resetear(){
  this.preciomaximo=5000;
  this.preciomini=0;

 

  this.cargavariable();
  window.location.reload();
}

ngOnInit(): void {
  setTimeout(function(){
    window.scrollTo(0, 0);
 }, 600);

 
    this.var=this.rutaActiva.snapshot.params.var;
   if(this.var=="."){

/*     window.location.reload();
 */   }
   else{
   /*  window.location.href = "/compra_bote"; */
   
   } 
   
   this.cargavariable();
/*    this.make(""); */
}

detallebote(x){
/*   window.location.href = '#/detalle_bote/'+x; */
     /*      window.location.reload(); */

     setTimeout(function(){
      window.location.reload();
   }, 300);
}

cargavariable(){

  this.id.length=0;
  this.convertido2.length=0;
  this.newarrary2.length=0;

  
  for (let i = 0; i < this.arraymantenimiento.length; i++) {
    if (this.arraymantenimiento[i].precio > this.preciomini && this.arraymantenimiento[i].precio <= this.preciomaximo) {
        this.newarrary2.push(this.arraymantenimiento[i]);
    }
} 

  this.id=this.newarrary2;
  this.convertido2.push(this.id);
  
}

filterss = [
{
  
  precio:0,
  make: "",
  model:"",
  condicion:"",
  kilometros:0,
  tipo:""

}
];

totalmake="";
totalmodel="";
totaltipe="";
totalcondicion="";
totalprecio = 0;
totalkilometro=0;



  make(v) {
    this.totalmake=v.target.value;  
    this.activar()
   
  }
  
  model(v){
    this.totalmodel=v.target.value;
    this.activar()
  }
  tipo(v){
    this.totaltipe=v.target.value;
    this.activar()
  }
  condicion(v){
    this.totalcondicion=v.target.value;
    this.activar()
  }
  
  kilometro(v){
    this.totalkilometro=v.target.value;

    this.activar()
  }

  package:any;
 
  activar(){
   
  /*  ***funcion de filto*** */ 
    function filterByMultipleFilters(data, filters) {
      return data.filter(item => {
        // Loop through all filters and check the current item
        for (const filter of filters) {
          for (const [key, value] of Object.entries(filter)) {
            if (!item[key] || item[key] !== value)
              return false;
          }
        }
        return true;
      });
    }
    /* fin funcion del filtro*** */


        /* filtro make**** */
        for(var i = 0; i < this.filterss.length; i++) {
          this.filterss[i].make=this.totalmake;
          if (this.filterss[i].make == "") {
          
            this.filterss.forEach(function(v){ delete v.make });
        
              break;
          }
          else{
            this.filterss[i].make=this.totalmake;
            break;
          }
        }
    /* *** */

            /* filtro model**** */
            for(var i = 0; i < this.filterss.length; i++) {
              this.filterss[i].model=this.totalmodel;
      
              if (this.filterss[i].model == "") {

                this.filterss.forEach(function(v){ delete v.model });
                break;
              }
              else{
                this.filterss[i].model=this.totalmodel;
                break;
              }
            }
        /* *** */


        
            /* filtro tipo**** */
            for(var i = 0; i < this.filterss.length; i++) {
              this.filterss[i].tipo=this.totaltipe;
              if (this.filterss[i].tipo == "") {
              
                this.filterss.forEach(function(v){ delete v.tipo });
                  break;
              }
              else{
                this.filterss[i].tipo=this.totaltipe;
                break;
              }
            }
        /* *** */

        /* filtro condicion**** */
        for(var i = 0; i < this.filterss.length; i++) {
          this.filterss[i].condicion=this.totalcondicion;
          if (this.filterss[i].condicion == "") {
          
            this.filterss.forEach(function(v){ delete v.condicion });
              break;
          }
          else{
            this.filterss[i].condicion=this.totalcondicion;
            break;
          }
        }
    /* *** */

      /* filtro kilometros**** */
      for(var i = 0; i < this.filterss.length; i++) {
        this.filterss[i].kilometros=this.totalkilometro;
        if (this.filterss[i].kilometros == 0) {
        
          this.filterss.forEach(function(v){ delete v.kilometros });
            break;
        }
        else{
          this.filterss[i].kilometros=Number(this.totalkilometro);
          break;
        }
      }
  /* *** */

    /* filtro precio**** */
  
          for(var i = 0; i < this.filterss.length; i++) {
            this.filterss[i].precio = this.totalprecio;
            if (this.filterss[i].precio == 0) {
              this.filterss.forEach(function(v){ delete v.precio });
              break;
            }
            else{
           
/*               this.filterss[i].precio = this.convertido2.precio;

 */                /* this.filterss[i].precio = 3000;
                if (this.filterss[i].precio > 1500 &&  this.filterss[i].precio < 2001) {                                  
                  this.convertido2.push(this.filterss[i])
                } */

                break;
            }
          }
     /* *** */

    /*  
    for (let i = 0; i < this.arraymantenimiento.length; i++) {
      if (this.arraymantenimiento[i].precio > 2000) {
          this.id.push(this.arraymantenimiento[i]);
      }
    } */ 


    
    this.id = filterByMultipleFilters(this.arraymantenimiento, this.filterss);



    this.convertido2.length=0; 
    this.convertido2.push(this.id);

    if(!this.convertido2[0].length){
      this.busquedanula="No se encontraron Resultados"
    }
    else{
      this.busquedanula=""
    }
 
    


    console.log("id",this.id)
    console.log("convertir",this.convertido2)
    console.log(this.filterss)

      /* this.filterss.splice(this.filterss.indexOf(this.totalestado), 1);  

    console.log(this.filterss);*/



  }

}
