<div class="section-title-page area-bg area-bg_dark area-bg_op_60">
  <div class="area-bg__inner">
    <div class="container text-center">
      <h1 class="b-title-page" style="margin-top: 50px;">Nosotros</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
         
        </ol>
      </nav>
   
    </div>
  </div>
</div>




<div class="l-main-content">
  <div class="ui-decor ui-decor_mirror ui-decor_sm-h bg-primary"></div>
  <div class="container">
      
      
      
      <div class="">
<!-- <div class="ui-decor ui-decor_down"></div> -->
<div class="container">

  <div class="row">
    <div class="col-lg-12"><br><br></div>
    <div class="col-lg-6 ">
      <div class="text-left">
        <h2 class="ui-title">Sobre Global Marine Centroamérica,
                          </h2>
        <div class="ui-content">
          <p class="justificado">
        Global Marine Centroamérica, empresa situada en el Estero de Jaltepeque, La Paz, ofrece variedad de servicios marinos, desde la administración de su embarcación a una amplia variedad de venta de barcos nuevos o usados. Gracias a la profesionalidad de esta empresa, la búsqueda de asesoramiento en la adquisición de embarcaciones queda garantizada.
          </p>
          <div class="gap25"></div> <span class="sign"></span>
                      
                      </div>
      </div>
    </div>
    <div class="col-lg-6"> <img src="../../assets/img/nosotros.jpg" alt="photo" class="img-fluid"> </div>
  </div>
</div>
</div>

<div class="section-advantages">
<div class="container">
  <div class="row">
    <div class="col-lg-4">
      <div class="b-advantages">
        <div class="ic flaticon-rudder-1 text-secondary"></div>
        <div class="b-advantages__main">
          <div class="b-advantages__title">Venta de botes y yates</div>
          <div class="decore01"></div>
          <div class="b-advantages__info">Ahorre tiempo, en Global Marine encontrará la embarcación a su gusto, nueva o usada</div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="b-advantages">
        <div class="ic flaticon-snorkel text-secondary"></div>
        <div class="b-advantages__main">
          <div class="b-advantages__title">Instalación y Mantenimiento</div>
          <div class="decore01"></div>
          <div class="b-advantages__info">Instalación de diferentes tipos de sistemas eléctricos, A/C, Bombas y demás accesorios que necesite</div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="b-advantages">
        <div class="ic flaticon-sailor text-secondary"></div>
        <div class="b-advantages__main">
          <div class="b-advantages__title">Servicios Administrativos </div>
          <div class="decore01"></div>
          <div class="b-advantages__info">Contamos con expertos para realizar todos sus trámites administrativos,  trámites en la AMP o avalúos </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>     
  
</div>


<!-- <div class="swiffy-slider slider-item-show4 slider-nav-outside slider-nav-dark slider-nav-sm slider-nav-visible slider-nav-page slider-item-snapstart slider-nav-autoplay slider-nav-autopause slider-item-ratio slider-item-ratio-contain slider-item-ratio-32x9 bg-white shadow-lg py-3 py-lg-4" data-slider-nav-autoplay-interval="2000">
  <div class="slider-container">
      <div><img src="assets/img/logos/Grady - White (2).png" alt="" (click)="refrescar('grady_white')"></div>
      <div><img src="assets/img/logos/Regulator (2).png" alt="" (click)="refrescar('regulator')" width="50"></div>
      <div><img src="assets/img/logos/Maritimo.png" alt="" (click)="refrescar('maritimo')"></div>
      <div><img src="assets/img/logos/schaefer-logo.png" alt="" (click)="refrescar('shaefer')"></div>
      <div><img src="assets/img/logos/Robalo (2).png" alt="" (click)="refrescar('robalo')"></div>
  </div>

  <button type="button" class="slider-nav" aria-label="Go left"></button>
  <button type="button" class="slider-nav slider-nav-next" aria-label="Go left"></button>

</div> -->


</div>

<section class="section-goods" style="padding-bottom: 0px;">
  <div class="section-default section-goods__inner bg-dark">
    <div class="ui-decor ui-decor_mirror ui-decor_center"></div>
    <div class="container">
      <div class="text-center">
        <h2 class="ui-title ui-title_light">Sit back. Relax. Nosotros nos encargamos del resto...</h2>
        <div class="row">
          <div class="col-md-8 offset-md-2">
             <img src="assets/img/decore03.png" alt="photo"> </div>
        </div>
      </div>
    </div>
  </div>
</section>

