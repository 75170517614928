import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
@Component({
  selector: 'app-marcas',
  templateUrl: './marcas.component.html',
  styleUrls: ['./marcas.component.css']
})
export class MarcasComponent implements OnInit {

  
  constructor(private rutaActiva: ActivatedRoute, private router: Router) {}
  var;
  var2;
  id=[];   
  convertido2 = [];
  convertido3 = [];
  estados="";
  precios="";
  refs="";
  nombre="";

  somefunctionName() {
    let link = ['/detalle_bote', '.', '1'];
    this.router.navigate(link);
}


arraymantenimiento = [{
  "imagen": "../../assets/newbote/canyon376.webp",
  "nombre": "Canyon 376",
  "precio": 1000,
  "make": "make 1",
  "model": "grady_white",
  "condicion": "Condicion 1",
  "kilometros": 450,
  "tipo":"Tipo 1",
  "minidescripcion": "La consola central de 37 pies Canyon 376 es el barco de pesca de agua salada",
  "url": "https://www.gradywhite.com/models/center-consoles/canyon-376/",
  "positionId": 1
},
{
  "imagen": "../../assets/newbote/canyon456.webp",
  "nombre": "Canyon 456",
  "precio": 2000,
  "make": "make 2",
  "model": "grady_white",
  "condicion": "Condicion 2",
  "kilometros": 300,
  "tipo":"Tipo 2",
  "minidescripcion": "barco insumergible más grande de su tipo, Grady-White establece el estándar en barcos",
  "url": "https://www.gradywhite.com/models/center-consoles/canyon-456/",
  "positionId": 2
},
{
  "imagen": "../../assets/newbote/freedom215.webp",
  "nombre": "Freedom 215",
  "precio": 3000,
  "make": "make 1",
  "model": "grady_white",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Repleto de lujo que normalmente no se ve en barcos de este tamaño y categoría",
  "url": "https://www.gradywhite.com/models/dual-consoles/freedom-215/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/Ice251.jpg",
  "nombre": "251 Coastal Explorer",
  "precio": 3000,
  "make": "make 1",
  "model": "grady_white",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "El 251 Coastal Explorer tiene un nombre apropiado. Construido sobre el casco SeaV² ® de Grady , el 251 ofrece",
  "url": "https://www.gradywhite.com/models/coastal-explorer/251-ce/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/Adventure208.webp",
  "nombre": "Adventure 208",
  "precio": 3000,
  "make": "make 1",
  "model": "grady_white",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Este bote con cabina de 20 pies de eslora es la base del legado de Grady-White. En su quinta década",
  "url": "https://www.gradywhite.com/models/walkaround-cabins/adventure-208/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/Express330.webp",
  "nombre": "Express 330",
  "precio": 3000,
  "make": "make 1",
  "model": "grady_white",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Nombrado uno de los 25 mejores barcos de pesca de agua salada en alta mar de todos los tiempos",
  "url": "https://www.gradywhite.com/models/express-cabins/express-330/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/regulator23.webp",
  "nombre": "Regulator 23",
  "precio": 3000,
  "make": "make 1",
  "model": "regulator",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Regulator 23 Ya sea que esté listo para una carrera en alta mar o un día informal más cerca de la costa,",
  "url": "https://www.regulatormarine.com/offshore/boat-models/23/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/regulator25.jpg",
  "nombre": "Regulator 25",
  "precio": 3000,
  "make": "make 1",
  "model": "regulator",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Hermosas líneas y el máximo rendimiento que va más allá de lo que esperarías en un barco",
  "url": "https://www.regulatormarine.com/offshore/boat-models/25/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/regulator28.jpg",
  "nombre": "Regulator 28",
  "precio": 3000,
  "make": "make 1",
  "model": "regulator",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Mejore el rendimiento con una máquina de pesca construida para la acción en alta mar.",
  "url": "https://www.regulatormarine.com/offshore/boat-models/28/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/regulator31.jpg",
  "nombre": "Regulator 31",
  "precio": 3000,
  "make": "make 1",
  "model": "regulator",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Bienvenido a bordo del barco más rápido de nuestra flota",
  "url": "https://www.regulatormarine.com/offshore/boat-models/31/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/regulator34.webp",
  "nombre": "Regulator 34",
  "precio": 3000,
  "make": "make 1",
  "model": "regulator",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "El Regulator 34 es una verdadera consola central,",
  "url": "https://www.regulatormarine.com/offshore/boat-models/34/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/regulator41.jpg",
  "nombre": "Regulator 41",
  "precio": 3000,
  "make": "make 1",
  "model": "regulator",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Conozca la próxima evolución del Regulator 41",
  "url": "https://www.regulatormarine.com/offshore/boat-models/41/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/r160.jpg",
  "nombre": "R160",
  "precio": 3000,
  "make": "make 1",
  "model": "robalo",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Le prometimos un cambio de juego y eso es exactamente lo que ofrece el nuevo R160 ",
  "url": "https://robalo.com/Robalo-Boat-X.php?id=591&action=tab_highlights&country=500",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/r180.jpg",
  "nombre": "R180",
  "precio": 3000,
  "make": "make 1",
  "model": "robalo",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "El Robalo R180 se diseñó especialmente pensando en la aventura.",
  "url": "https://robalo.com/Robalo-Boat-X.php?id=592&action=tab_highlights&country=500#",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/r200.jpg",
  "nombre": "R200",
  "precio": 3000,
  "make": "make 1",
  "model": "robalo",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Siéntese y haga una lista de verificación de exactamente",
  "url": "https://robalo.com/Robalo-Boat-X.php?id=593&action=tab_highlights&country=500#",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/r222.jpg",
  "nombre": "R222",
  "precio": 3000,
  "make": "make 1",
  "model": "robalo",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Tenía que pasar. Aprovechando el éxito revolucionario del R180 y el R200",
  "url": "https://robalo.com/Robalo-Boat-X.php?id=594&action=tab_highlights&country=500#",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/R230.jpg",
  "nombre": "R230",
  "precio": 3000,
  "make": "make 1",
  "model": "robalo",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "El Robalo R230 está diseñado para satisfacer todas sus necesidades",
  "url": "https://robalo.com/Robalo-Boat-X.php?id=595&action=tab_highlights&country=500#",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/r242.jpg",
  "nombre": "R242",
  "precio": 3000,
  "make": "make 1",
  "model": "robalo",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "El sensacional R242 es una de las ofertas de consola central más nuevas de Robalo",
  "url": "https://robalo.com/Robalo-Boat-X.php?id=596&action=tab_highlights&country=500#",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/m1.jpg",
  "nombre": "MARITIMO M51",
  "precio": 3000,
  "make": "make 1",
  "model": "maritimo",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "El Maritimo M51 combina un rendimiento impresionante y características impresionantes incorporadas en el inconfundible diseño de Maritimo",
  "url": "https://www.maritimo.com.au/model/maritimo-m51/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/m2.jpg",
  "nombre": "MARITIMO M55",
  "precio": 3000,
  "make": "make 1",
  "model": "maritimo",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Con un delicado equilibrio entre potencia y juego, el nuevo Maritimo M55 2020 fusiona los últimos avances en tecnología náutica ",
  "url": "https://www.maritimo.com.au/model/maritimo-m55/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/m3.jpg",
  "nombre": "MARITIMO M59",
  "precio": 3000,
  "make": "make 1",
  "model": "maritimo",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Suba a bordo donde le espera un santuario tranquilo, creado a través de una elegante sencillez y lujosos acabados. ",
  "url": "https://www.maritimo.com.au/model/maritimo-m59-usa/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/m4.jpg",
  "nombre": "MARITIMO M64",
  "precio": 3000,
  "make": "make 1",
  "model": "maritimo",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "El yate a motor Maritimo M64 es efectivamente nuestro 'mini' M70, aunque con sesenta y cuatro pies es un barco",
  "url": "https://www.maritimo.com.au/model/maritimo-m64/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/m5.jpg",
  "nombre": "MARITIMO S51",
  "precio": 3000,
  "make": "make 1",
  "model": "maritimo",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "El magnífico S51 ofrece todas las instalaciones y beneficios de los buques más grandes de Maritimo.",
  "url": "https://www.maritimo.com.au/model/maritimo-s51/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/m6.jpg",
  "nombre": "MARITIMO S51",
  "precio": 3000,
  "make": "make 1",
  "model": "",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "El magnífico S51 ofrece todas las instalaciones y beneficios de los buques más grandes de Maritimo en un paquete ",
  "url": "https://www.maritimo.com.au/model/maritimo-s51/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/v33.webp",
  "nombre": " Schaefer V33",
  "precio": 3000,
  "make": "make 1",
  "model": "shaefer",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "El nuevo Schaefer V33 es un walk-around contemporáneo y deportivo de 33.9 pies, alineado con lo más moderno",
  "url": "https://www.schaeferyachts.us/schaefer-v33",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/V777.webp",
  "nombre": "Schaefer 770",
  "precio": 3000,
  "make": "make 1",
  "model": "shaefer",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Desde su creación hace más de 29 años, Schaefer Yachts ha invertido continuamente en investigación",
  "url": "https://www.schaeferyachts.us/schaefer-770",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/V660.webp",
  "nombre": "Schaefer 660",
  "precio": 3000,
  "make": "make 1",
  "model": "shaefer",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "El Schaefer 660 impresiona por su innovación, diseño y sofisticación. Los dos nuevos balcones laterales desplegables ",
  "url": "https://www.schaeferyachts.us/schaefer-660",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/V600.webp",
  "nombre": "Schaefer 600",
  "precio": 3000,
  "make": "make 1",
  "model": "shaefer",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Conocemos los caminos y desafíos para posicionarnos por delante de la competencia, pero más que eso, ",
  "url": "https://www.schaeferyachts.us/schaefer-600",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/v510.webp",
  "nombre": "Schaefer 510 GT",
  "precio": 3000,
  "make": "make 1",
  "model": "shaefer",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Presentamos el nuevo Schaefer 510 GT Pininfarina. Lo que ya era un yate increíble ahora es mejor.",
  "url": "https://www.schaeferyachts.us/schaefer-510gt-pininfarina",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/v511.webp",
  "nombre": "Schaefer 510 Sport",
  "precio": 3000,
  "make": "make 1",
  "model": "shaefer",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "ontempla el Schaefer 510 Sport Pininfarina Comenzando con los 25 años de artesanía de calidad de Schaefer",
  "url": "https://www.schaeferyachts.us/schaefer-510-sport-pininfarina",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/415cs.jpg",
  "nombre": "415 CS",
  "precio": 3000,
  "make": "make 1",
  "model": "solace",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Our newest model that is showcasing at the Fort Lauderdale International Boat Show!! Please check back soon for more details.  ",
  "url": "https://solaceboats.com/solace-415/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/29cs.jpg",
  "nombre": "29 CS",
  "precio": 3000,
  "make": "make 1",
  "model": "solaces",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "The 29 CS will be providing captains the ability to venture places by water where he may have never been and to catch many.  ",
  "url": "https://solaceboats.com/solace-29/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/32cs.jpg",
  "nombre": "32 CS",
  "precio": 3000,
  "make": "make 1",
  "model": "solace",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "The benefits provided by this 32′ hybrid center console are beyond imaginable. Inshore, offshore, nearshore…you pick. This boat is so well.  ",
  "url": "https://solaceboats.com/solace-32/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/41cs.jpg",
  "nombre": "41 CS",
  "precio": 3000,
  "make": "make 1",
  "model": "solace",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "The 41 CS incorporates more family and fishing features into one center console boat than the boating industry has ever seen.  ",
  "url": "https://solaceboats.com/solace-41/",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/chapa1.jpg",
  "nombre": "21 SSI",
  "precio": 3000,
  "make": "make 1",
  "model": "chaparral",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Everything that has made Chaparral's wildly popular SSi Series the talk of the waterways, gets bigger and better with the amazing 21 SSi.   ",
  "url": "https://www.chaparralboats.com/Chaparral-Boat.php?id=677&action=tab_highlights&country=226",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/chapa2.jpg",
  "nombre": "21 SSI SKI & FISH",
  "precio": 3000,
  "make": "make 1",
  "model": "chaparral",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "The new 21 Ski & Fish SSi combines the best of everything. Larger casting platforms, abundant storage, and flexible seating cockpit design.   ",
  "url": "https://www.chaparralboats.com/Chaparral-Boat.php?id=678&action=tab_highlights&country=226",
  "positionId": 3
},
{
  "imagen": "../../assets/newbote/chapa3.jpg",
  "nombre": "23 SSI",
  "precio": 3000,
  "make": "make 1",
  "model": "chaparral",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "minidescripcion": "Everything that has made Chaparral's wildly popular SSi Series the talk of the waterways, gets bigger and better with the amazing 23 SSi.  ",
  "url": "https://www.chaparralboats.com/Chaparral-Boat.php?id=681&action=tab_highlights&country=226",
  "positionId": 3
}
]

marcas = [{
  "imagen": "../../assets/newimg/nuevos_logos/Grady-white.png",
  "marca" : "Grady White",
  "model": "grady_white",
  "descrip": "¡Los destinos son ilimitados en esta versátil consola central de 21 pies!",
  
},{
  "imagen": "../../assets/newimg/nuevos_logos/regulator.png",
  "marca" : "REGULATOR",
  "model": "regulator",
  "descrip": "Más funciones de pesca, actualizaciones de alta tecnología y mucho más",
  
},{
  "imagen": "../../assets/newimg/nuevos_logos/Robalo.png",
  "marca" : "Robalo",
  "model": "robalo",
  "descrip": "La opción n.º 1 de Estados Unidos para barcos de pesca fuera de borda ",
  
},{
  "imagen": "../../assets/newimg/nuevos_logos/Maritimo.png",
  "marca" : "Maritimo",
  "model": "maritimo",
  "descrip": "Maritimo está inspirado en una pasión de toda la vida por el rendimiento",

},{
  "imagen": "../../assets/newimg/nuevos_logos/Schaefer.png",
  "marca" : "Shaefer",
  "model": "shaefer",
  "descrip": "REFERENCIA EN INNOVACIÓN EN EL MERCADO NÁUTICO.",
  
},{
  "imagen": "../../assets/newimg/nuevos_logos/Solace.png",
  "marca" : "Solace",
  "model": "solace",
  "descrip": "REFERENCIA EN INNOVACIÓN EN EL MERCADO NÁUTICO.",
  
},{
  "imagen": "../../assets/newimg/nuevos_logos/Chaparral.png",
  "marca" : "Chaparral",
  "model": "chaparral",
  "descrip": "REFERENCIA EN INNOVACIÓN EN EL MERCADO NÁUTICO.",
  
}]

newarrary2=[];

preciomini=0;
preciomaximo=5000;

totalminimo=0;
totalmaximo=0;

preciominimo(v){
  this.preciomini=v.target.value;
  this.totalminimo=v.target.value;
  this.cargavariable()
}

preciomaximos(v){
  this.preciomaximo=v.target.value;
  this.totalmaximo=v.target.value;
  this.cargavariable()
}

resetear(){
  this.preciomaximo=5000;
  this.preciomini=0;


  this.cargavariable();
}

img;

ngOnInit(): void {
    this.var=this.rutaActiva.snapshot.params.var;
    this.var2=this.rutaActiva.snapshot.params.var2;


    if(this.var2=="grady_white"){
      this.img="assets/img/logos/gradywhite-logo-black.png";
    }
    if(this.var2=="regulator"){
      this.img="assets/img/logos/Regulator (2).png";
    }
    if(this.var2=="maritimo"){
      this.img="assets/img/logos/Maritimo.png";
    }
    if(this.var2=="shaefer"){
      this.img="assets/img/logos/schaefer-logo.png";
    }
    if(this.var2=="robalo"){
      this.img="assets/img/logos/robalo.png";
    }
    if(this.var2=="solace"){
      this.img="assets/img/logos/solace.png";
    }
    if(this.var2=="chaparral"){
      this.img="assets/img/logos/chaparral.png";
    }
   

   
    setTimeout(function(){
      window.scrollTo(0, 0);
   }, 100);

   if(this.var == "."){
    window.location.reload();
  
    /* window.location.href = "#/marcas/_/"+this.var2; */
   
   }
   else{
    /* window.location.href = "#/marcas/_/"+this.var2; */
    setTimeout(function(){
      window.scrollTo(0, 0);
   }, 600);
   
   }
   this.activar2()
}

activar2(){
  let id = this.arraymantenimiento.filter(
    arraymantenimiento => arraymantenimiento.model == this.var2);

    let id2 = this.marcas.filter(
      marcas => marcas.model == this.var2);
  

    this.convertido2.length=0;
    this.convertido2.push(id);

    this.convertido3.length=0;
    this.convertido3.push(id2);


}

cargavariable(){

  this.id.length=0;
  this.convertido2.length=0;
  this.newarrary2.length=0;

  
  for (let i = 0; i < this.arraymantenimiento.length; i++) {
    if (this.arraymantenimiento[i].precio > this.preciomini && this.arraymantenimiento[i].precio <= this.preciomaximo) {
        this.newarrary2.push(this.arraymantenimiento[i]);
    }
} 

  this.id=this.newarrary2;
  this.convertido2.push(this.id);
}

filterss = [
{
  
  precio:0,
  make: "",
  model:"",
  condicion:"",
  kilometros:0,
  tipo:""

}
];

totalmake="";
totalmodel="";
totaltipe="";
totalcondicion="";
totalprecio = 0;
totalkilometro=0;



  make(v) {
    this.totalmake=v.target.value;  
    this.activar()
   
  }
  
  model(v){
    this.totalmodel=v.target.value;
    this.activar()
  }
  tipo(v){
    this.totaltipe=v.target.value;
    this.activar()
  }
  condicion(v){
    this.totalcondicion=v.target.value;
    this.activar()
  }
  
  kilometro(v){
    this.totalkilometro=v.target.value;

    this.activar()
  }

  package:any;
 
  activar(){
   
  /*  ***funcion de filto*** */ 
    function filterByMultipleFilters(data, filters) {
      return data.filter(item => {
        // Loop through all filters and check the current item
        for (const filter of filters) {
          for (const [key, value] of Object.entries(filter)) {
            if (!item[key] || item[key] !== value)
              return false;
          }
        }
        return true;
      });
    }
    /* fin funcion del filtro*** */


        /* filtro make**** */
        for(var i = 0; i < this.filterss.length; i++) {
          this.filterss[i].make=this.totalmake;
          if (this.filterss[i].make == "") {
          
            this.filterss.forEach(function(v){ delete v.make });
        
              break;
          }
          else{
            this.filterss[i].make=this.totalmake;
            break;
          }
        }
    /* *** */

            /* filtro model**** */
            for(var i = 0; i < this.filterss.length; i++) {
              this.filterss[i].model=this.totalmodel;
      
              if (this.filterss[i].model == "") {

                this.filterss.forEach(function(v){ delete v.model });
                break;
              }
              else{
                this.filterss[i].model=this.totalmodel;
                break;
              }
            }
        /* *** */


        
            /* filtro tipo**** */
            for(var i = 0; i < this.filterss.length; i++) {
              this.filterss[i].tipo=this.totaltipe;
              if (this.filterss[i].tipo == "") {
              
                this.filterss.forEach(function(v){ delete v.tipo });
                  break;
              }
              else{
                this.filterss[i].tipo=this.totaltipe;
                break;
              }
            }
        /* *** */

        /* filtro condicion**** */
        for(var i = 0; i < this.filterss.length; i++) {
          this.filterss[i].condicion=this.totalcondicion;
          if (this.filterss[i].condicion == "") {
          
            this.filterss.forEach(function(v){ delete v.condicion });
              break;
          }
          else{
            this.filterss[i].condicion=this.totalcondicion;
            break;
          }
        }
    /* *** */

      /* filtro kilometros**** */
      for(var i = 0; i < this.filterss.length; i++) {
        this.filterss[i].kilometros=this.totalkilometro;
        if (this.filterss[i].kilometros == 0) {
        
          this.filterss.forEach(function(v){ delete v.kilometros });
            break;
        }
        else{
          this.filterss[i].kilometros=Number(this.totalkilometro);
          break;
        }
      }
  /* *** */

    /* filtro precio**** */
  
          for(var i = 0; i < this.filterss.length; i++) {
            this.filterss[i].precio = this.totalprecio;
            if (this.filterss[i].precio == 0) {
              this.filterss.forEach(function(v){ delete v.precio });
              break;
            }
            else{
           
/*               this.filterss[i].precio = this.convertido2.precio;

 */                /* this.filterss[i].precio = 3000;
                if (this.filterss[i].precio > 1500 &&  this.filterss[i].precio < 2001) {                                  
                  this.convertido2.push(this.filterss[i])
                } */

                break;
            }
          }
     /* *** */

    /*  
    for (let i = 0; i < this.arraymantenimiento.length; i++) {
      if (this.arraymantenimiento[i].precio > 2000) {
          this.id.push(this.arraymantenimiento[i]);
      }
    } */ 


    
    this.id = filterByMultipleFilters(this.arraymantenimiento, this.filterss);



    this.convertido2.length=0; 
    this.convertido2.push(this.id);
 
    


    console.log("id",this.id)
    console.log("convertir",this.convertido2)
    console.log(this.filterss)

      /* this.filterss.splice(this.filterss.indexOf(this.totalestado), 1);  

    console.log(this.filterss);*/



  }

}
