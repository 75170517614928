import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
/* import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import {NgForm} from '@angular/forms';

 */

@Component({
  selector: 'app-detalle-bote',
  templateUrl: './detalle-bote.component.html',
  styleUrls: ['./detalle-bote.component.css']
})

export class DetalleBoteComponent implements OnInit {

  constructor(private rutaActiva: ActivatedRoute, private http: HttpClient) {}
  var;
  var2;

  convertido2 = [];

  /* *** */

  emailstring= "mailto:wilmangar2016@gmail.com?Subject=Hello&body=links:  %0D http://link1.com  %0D http://link1.com";










  async enviar(){



 /*  let params ={
      email:"devweb@premierdistributions.com",
      name:"prueba",
      mensaje:"hola wilman"
    }

    this.http.post('http://apilayer.net/api/check?access_key=6423b8c1f60cba2b39488e329c5f3633&email=wilmangar2016@gmail.com&smtp=1&format=1',params).subscribe(resp=>{
      alert("enviado")
    }) */
  }
  /* *** */
  
arraymantenimiento = [{
  "imagen": "../../assets/newbote/b1.webp",
  "nombre": "Four Winns 34",
  "precio": 1000,
  "make": "make 1",
  "model": "model 1",
  "condicion": "Condicion 1",
  "kilometros": 450,
  "tipo":"Tipo 1",
  "descipcion": "Es una combinación notable de rendimiento de embarcación deportiva y comodidades similares a las de un crucero. Desde exclusivos asientos estilo diván hasta un compartimento completo para la cabeza y una litera, todo en este barco fue cuidadosamente diseñado y elaborado para satisfacer al navegante más exigente.",
  "anios": 7,
  "motores": "Volvo Penta",
  "slider1": "../../assets/newbote/b1.webp",
  "slider2": "../../assets/newbote/b2.webp",
  "slider3": "../../assets/newbote/b5.webp",
  "slider4": "../../assets/newbote/b1.webp",
  "positionId": 1
},
{
  "imagen": "../../assets/newbote/b2_1.webp",
  "nombre": "Intrepid 2010",
  "precio": 2000,
  "make": "make 2",
  "model": "model 1",
  "condicion": "Condicion 2",
  "kilometros": 300,
  "tipo":"Tipo 2",
  "descipcion": "Excelente ejemplar, equipado con triples motores fuera de borda Suzuki de 300 Hp. El barco perfecto para pescar, ir de playa en playa y navegar.",
  "anios": 7,
  "motores": "triple Yamaha 425 del 2021",
  "slider1": "../../assets/newbote/b2_1.webp",
  "slider2": "../../assets/newbote/b2_2.webp",
  "slider3": "../../assets/newbote/b2_3.webp",
  "slider4": "../../assets/newbote/b2_4.webp",
  "positionId": 2
},
{
  "imagen": "../../assets/newbote/b3_1.webp",
  "nombre": "FREEDOM 33.5",
  "precio": 3000,
  "make": "make 1",
  "model": "model 2",
  "condicion": "Condicion 2",
  "kilometros": 200,
  "tipo":"Tipo 1",
  "descipcion": "Esta consola doble de 33 pies es una excelente embarcación de agua salada, perfecta para la pesca en alta mar, pero repleta de comodidades, ¡lo que lo convierte en muchos días excepcionales en el agua! Además de dos áreas de consola que incluyen una cabecera de fácil acceso y una litera espaciosa, el  Freedom 335  incluye servicios de entretenimiento superiores completos con barra con fregadero, refrigerador y parrilla. Con asientos ultra cómodos y amplios en la proa, un asiento de banco envolvente con mesa y el banco plegable de popa patentado de Grady que se puede cerrar para permitir más espacio en la cabina cuando se pesca, este es el barco perfecto para familias multigeneracionales que desean disfrutar el tiempo juntos. en el agua.",
  "anios": 7,
  "motores": "",
  "slider1": "../../assets/newbote/b3_1.webp",
  "slider2": "../../assets/newbote/b3_4.webp",
  "slider3": "../../assets/newbote/b3_3.webp",
  "slider4": "../../assets/newbote/b3_1.webp",
  "positionId": 3
}
]

  ngOnInit(): void {



    setTimeout(function(){
      window.scrollTo(0, 0);
   }, 700);

   
    this.var=this.rutaActiva.snapshot.params.var;
    this.var2=this.rutaActiva.snapshot.params.var2;
    if(this.var=="."){
   
     /* window.location.href = "#/detalle_bote/../"+this.var2; */
     window.location.reload();

    }
    else{
     /* window.location.href = "#/detalle_bote/../"+this.var2; */
    
    }

    this.activar()
  }

  

activar(){
  let id = this.arraymantenimiento.filter(
    arraymantenimiento => arraymantenimiento.positionId == this.var2);
    this.convertido2.length=0;
    this.convertido2.push(id);

}

}
