import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'global-new';
   mostrar="";
  abrirmenu() {
    this.mostrar='margin-left: -255px; display: block; transition-duration: 300ms; transform: translate(255px, 0px);';
    
   }
   x;
   refrescar(e){
  
          setTimeout(function(){
            window.location.href = '#/marcas/'+e;
            window.location.reload();
         }, 100);
   }
   irhome(){
    window.location.href = '#/home';
    window.location.reload();
  }

  comprabote(){
  /*   window.location.href = '/compra_bote'; */
  setTimeout(function(){
    window.location.reload();
 }, 300);


  }

  constructor(private http: HttpClient) { }

  enviarcorreo(nombre: string, apellido: string, correo: string, area: string, telefono: string, mensaje: string){
  
    if(nombre=="" || apellido==""|| correo==""|| area==""|| telefono==""|| mensaje=="")
    {
      alert("Complete todos los campos del formulario")
    }
    else{
    this.http.post<any>('https://formspree.io/f/xwkyanko', { Titulo: 'Formulario de Contacto', Nombre: nombre, Apellido: apellido, Correo: correo,Telefono:area+telefono, Mensaje:mensaje  }).subscribe(data => { })

    alert("Correo Enviado con Exito");
    window.location.reload();
    }
   
  }
}
